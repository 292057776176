import { Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ErrorPage from "src/pages/error/ErrorPage";
import UsedEmailVerificationPage from "src/pages/error/UsedEmailVerificationPage";
import AlreadyVerifiedPage from "src/pages/error/AlreadyVerifiedPage";
import VerificationRequiredPage from "src/pages/error/VerificationRequiredPage";
import VerificationExpiredPage from "src/pages/error/VerificationExpiredPage";
import MaintenancePage from "src/pages/maintenance/MaintenancePage";
import InactivePage from "../pages/account/Inactive/InactivePage";
import SetupLayout from "../pages/Setup/SetupLayout";
import SetupInfo from "../pages/Setup/SetupInfo";
import SetupUpload from "../pages/Setup/SetupUpload";
import SetupIntegrations from "../pages/Setup/SetupIntegrations";
import ProtectedRoute from "./ProtectedRoute";
import SignUp from "./SignUp";
import Invitation from "./Invitation";
import Fallback from "./Fallback";
import Redirect from "./Redirect";
import RedirectToLogin from "./RedirectToLogin";
import lazyWithRetry from "src/helpers/lazyWithRetry";
import Layout from "src/components/Layout/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getClientUuid,
  isClientReadOnly,
  isDisallowed,
} from "../helpers/security";
import useClientNavigate from "src/hooks/useClientNavigate";
// FYI if you change this import to be lazyWithRetry, the AccountDetails e2e test will start failing
// No clue why, might need to look into it later
import AccountsPage from "src/pages/Accounts/AccountsPage";
import UuidRedirect from "./UuidRedirect";

const UserProfileSettingsPage = lazyWithRetry(
  () => import("src/pages/account/Settings/UserProfileSettingsPage")
);
const SetupStart = lazyWithRetry(() => import("src/pages/Setup/SetupStart"));
const ApiUsagePage = lazyWithRetry(
  () => import("src/pages/account/ApiUsage/ApiUsagePage")
);
const ApiKeysPage = lazyWithRetry(
  () => import("src/pages/account/ApiKeys/ApiKeysPage")
);
const UpgradePage = lazyWithRetry(
  () => import("src/pages/account/Upgrade/UpgradePage")
);
const AccountSettingsPage = lazyWithRetry(
  () => import("src/pages/account/Settings/AccountSettingsPage")
);
const EventExplorerPage = lazyWithRetry(
  () => import("src/pages/EventExplorer/EventExplorerPage")
);
const SignalPrintPage = lazyWithRetry(
  () => import("src/pages/SignalPrint/SignalPrintPage")
);
const DashboardOverviewPage = lazyWithRetry(
  () => import("src/pages/Dashboards/DashboardPage")
);
const ContactSupportPage = lazyWithRetry(
  () => import("src/pages/Contact/ContactSupportPage")
);
const ContactSalesPage = lazyWithRetry(
  () => import("src/pages/Contact/ContactSalesPage")
);
const ListsPage = lazyWithRetry(() => import("src/pages/Lists/ListsPage"));
const ListEditPage = lazyWithRetry(
  () => import("src/pages/Lists/ListEditPage")
);
const ListCreatePage = lazyWithRetry(
  () => import("src/pages/Lists/ListCreatePage")
);
const InsightsPage = lazyWithRetry(
  () => import("src/pages/insights/InsightsPage")
);
const AccountDetailsPage = lazyWithRetry(
  () => import("src/pages/Accounts/AccountDetailsPage")
);
const WorkflowsListPage = lazyWithRetry(
  () => import("src/pages/Workflows/WorkflowsListPage")
);
const WorkflowCreatePage = lazyWithRetry(
  () => import("src/pages/Workflows/WorkflowCreatePage")
);
const WorkflowEditPage = lazyWithRetry(
  () => import("src/pages/Workflows/WorkflowEditPage")
);

const AppRouter = () => {
  const { isAuthenticated } = useAuth0();
  const readOnly = isClientReadOnly();
  const clientUUID = getClientUuid();
  const navigate = useClientNavigate();

  const disallowed = isDisallowed();
  useEffect(() => {
    if (disallowed) {
      navigate("/");
    }
  }, [disallowed]);

  return (
    <Routes>
      <Route path="/" element={<Redirect />} />
      <Route path="/health" element={<h3>Ok</h3>} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/invited" element={<Invitation />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/used-link" element={<UsedEmailVerificationPage />} />
      <Route
        path="/verification-required"
        element={<VerificationRequiredPage />}
      />
      <Route path="/already-verified" element={<AlreadyVerifiedPage />} />
      <Route
        path="/verification-expired"
        element={<VerificationExpiredPage />}
      />
      <Route path="/maintenance" element={<MaintenancePage />} />
      {isAuthenticated && (
        <Route>
          <Route path="/account/inactive" element={<InactivePage />} />
          {!readOnly && (
            <Route element={<SetupLayout />}>
              <Route
                path="/setup/start"
                element={
                  <Suspense fallback={<Fallback />}>
                    <ProtectedRoute component={SetupStart} />
                  </Suspense>
                }
              />
              <Route
                path="/setup/info"
                element={<ProtectedRoute component={SetupInfo} />}
              />
              <Route
                path="/setup/upload"
                element={<ProtectedRoute component={SetupUpload} />}
              />
              <Route
                path="/setup/integrations"
                element={<ProtectedRoute component={SetupIntegrations} />}
              />
            </Route>
          )}
          <Route element={<Layout />}>
            {/* Redirect to dashboard page if no path specified */}
            <Route path={`${clientUUID}`} element={<Redirect />} />
            <Route
              path={`${clientUUID}/dashboard`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={DashboardOverviewPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/threats`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={InsightsPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/ai-insights`}
              element={<Navigate to={`/${clientUUID}/threats`} replace />}
            />
            <Route
              path={`${clientUUID}/workflows`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={WorkflowsListPage} />
                </Suspense>
              }
            />

            {!readOnly && (
              <Route
                path={`${clientUUID}/workflows/create`}
                element={
                  <Suspense fallback={<Fallback />}>
                    <ProtectedRoute component={WorkflowCreatePage} />
                  </Suspense>
                }
              />
            )}
            <Route
              path={`${clientUUID}/workflows/edit/:uuid`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={WorkflowEditPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/lists`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={ListsPage} />
                </Suspense>
              }
            />
            {!readOnly && (
              <Route
                path={`${clientUUID}/lists/create`}
                element={
                  <Suspense fallback={<Fallback />}>
                    <ProtectedRoute component={ListCreatePage} />
                  </Suspense>
                }
              />
            )}
            <Route
              path={`${clientUUID}/lists/edit/:uuid`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={ListEditPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/contact-support`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={ContactSupportPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/contact-sales`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={ContactSalesPage} />
                </Suspense>
              }
            />
            {!readOnly && (
              <Route>
                <Route
                  path={`${clientUUID}/account/upgrade`}
                  element={
                    <Suspense fallback={<Fallback />}>
                      <ProtectedRoute component={UpgradePage} />
                    </Suspense>
                  }
                />
                <Route
                  path={`${clientUUID}/account/plans`}
                  element={
                    <Suspense fallback={<Fallback />}>
                      <ProtectedRoute component={ApiUsagePage} />
                    </Suspense>
                  }
                />
                <Route
                  path={`${clientUUID}/account/api-keys`}
                  element={
                    <Suspense fallback={<Fallback />}>
                      <ProtectedRoute component={ApiKeysPage} />
                    </Suspense>
                  }
                />
                <Route
                  path={`${clientUUID}/account/account-settings`}
                  element={
                    <Suspense fallback={<Fallback />}>
                      <ProtectedRoute component={AccountSettingsPage} />
                    </Suspense>
                  }
                />
              </Route>
            )}
            <Route
              path={`${clientUUID}/account/user-settings`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={UserProfileSettingsPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/signalprint`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={SignalPrintPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/event-explorer`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={EventExplorerPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/accounts`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={AccountsPage} />
                </Suspense>
              }
            />
            <Route
              path={`${clientUUID}/accounts/account-details/:uuid`}
              element={
                <Suspense fallback={<Fallback />}>
                  <ProtectedRoute component={AccountDetailsPage} />
                </Suspense>
              }
            />
          </Route>
          <Route path="*" element={<UuidRedirect />} />
        </Route>
      )}
      <Route path="*" element={<RedirectToLogin />} />
    </Routes>
  );
};

export default AppRouter;
