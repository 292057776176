// @ts-nocheck
/* istanbul ignore file */
import CssBaseline from "@mui/material/CssBaseline";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Easter from "src/components/Easter/EasterEgg";
import BoundaryFallback from "./components/BoundaryFallback";
import { appErrorHandler } from "./helpers/utils";
import store, { persistor } from "./redux";
import RouteWrapper from "src/routes/RouteWrapper";
import appTheme from "./styles/mainTheme";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./App.css";

const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={BoundaryFallback}
      onError={appErrorHandler}
      onReset={() => {
        window.location.reload(false);
      }}
    >
      <ReduxProvider store={store}>
        <PersistGate loading={<LinearProgress />} persistor={persistor}>
          <SnackbarProvider
            maxSnack={3}
            domRoot={document.getElementById("notistack-container")}
          >
            <ThemeProvider theme={appTheme}>
              <CssBaseline />
              <RouteWrapper />
            </ThemeProvider>
          </SnackbarProvider>
        </PersistGate>
      </ReduxProvider>
      <Easter />
    </ErrorBoundary>
  );
};

export default App;
