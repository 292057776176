import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

// this function will tell you if the time
// is within the last 180 days
export function within180Days(time: string) {
  const t = dayjs(time).utc();
  const oneHundredEightyDaysAgo = dayjs().utc().subtract(180, "day");

  return t.isAfter(oneHundredEightyDaysAgo);
}

// this function will give you a time that is up to some number
// of days ago, if that number is past 180 days then it will go as
// far back as possible up to the 180 day mark
export function upToDays(time: string, numberOfDays: number) {
  const daysBack = dayjs(time).utc().subtract(numberOfDays, "day");
  const oneHundredEightyDaysAgo = dayjs().utc().subtract(180, "day");

  if (daysBack.isAfter(oneHundredEightyDaysAgo)) {
    return daysBack.toISOString();
  }

  return oneHundredEightyDaysAgo.toISOString();
}

// Given hoursSinceFirstSeen & lastSeen timestamp, returns firstSeen timestamp
export function calculateFirstSeen(
  hoursSinceFirstSeen: number,
  lastSeenTimestamp: string
) {
  if (hoursSinceFirstSeen === 0) {
    return lastSeenTimestamp;
  }
  return lastSeenTimestamp && hoursSinceFirstSeen
    ? dayjs(lastSeenTimestamp)
        .subtract(hoursSinceFirstSeen, "hour")
        .toISOString()
    : "";
}

export function formatAbsoluteTime(timestamp: string) {
  return dayjs(timestamp).format("M/D/YYYY h:mm:ss A");
}
export function formatRelativeTime(timestamp: string) {
  return dayjs(new Date(timestamp)).from(dayjs());
}
export function isWithinLastHour(timestamp: string) {
  return new Date().getTime() - new Date(timestamp).getTime() <= 60 * 60 * 1000;
}
export const last24Hours = [
  dayjs().subtract(24, "hour").toISOString(),
  dayjs().toISOString(),
];

export const last7Days = [
  dayjs().subtract(7, "day").toISOString(),
  dayjs().toISOString(),
];

export const last30Days = [
  dayjs().subtract(30, "day").toISOString(),
  dayjs().toISOString(),
];

export const last6Months = [
  dayjs().utc().subtract(180, "day").toISOString(),
  dayjs().toISOString(),
];

// Ex: given "2021-01-01T00:00:00Z" and "3d", returns "Jan 1 - Jan 4"
export function formatDateRange(date: string, granularity: string): string {
  const dateObj = dayjs(date);

  // Extract numeric value and unit from granularity (e.g., "3d" -> 3 and "d")
  const count = parseInt(granularity.slice(0, -1));
  const unit = granularity[granularity.length - 1];

  // Map unit to Day.js recognized units
  let dayjsUnit: dayjs.ManipulateType;
  switch (unit) {
    case "w":
      dayjsUnit = "week";
      break;
    case "d":
      dayjsUnit = "day";
      break;
    case "h":
      dayjsUnit = "hour";
      break;
    case "m":
      dayjsUnit = "minute";
      break;
    case "s":
      dayjsUnit = "second";
      break;
    default:
      dayjsUnit = "day";
  }

  // Choose a format string based on the unit.
  // (You can customize these as needed.)
  let formatStr = "";
  switch (unit) {
    case "w":
      formatStr = "MMM YYYY";
      break;
    case "d":
      formatStr = "MMM D";
      break;
    case "h":
      formatStr = "MMM D, h A";
      break;
    case "m":
      formatStr = "h:mm A";
      break;
    case "s":
      formatStr = "h:mm:ss A";
      break;
    default:
      formatStr = "MMM D, YYYY h:mm A";
  }

  // Format the start date
  const startFormatted =
    unit === "w" || unit === "d"
      ? dateObj.utc().format(formatStr)
      : dateObj.format(formatStr);

  // If count is not provided or is 1, return the single formatted date.
  if (!count || count === 1) {
    return startFormatted;
  }

  // Compute the end date by adding (count - 1) units
  const endDateObj = dateObj.add(count - 1, dayjsUnit);
  const endFormatted =
    unit === "w" || unit === "d"
      ? endDateObj.utc().format(formatStr)
      : endDateObj.format(formatStr);

  return `${startFormatted} - ${endFormatted}`;
}
