// @ts-nocheck
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "src/hooks/useDocumentTitle";
import Footer from "src/components/Layout/Footer";
import comet from "../../assets/comet.png";
import dino from "../../assets/dino.png";

const NotFoundPage = () => {
  const { t } = useTranslation();
  useDocumentTitle("");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
      spacing={0}
    >
      <Container maxWidth="md">
        <Grid container direction="column" justifyContent="center">
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <img
                  height="75%"
                  width="auto"
                  alt={t("notFound.commetAlt")}
                  src={comet}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  sx={{ textAlign: "center" }}
                >
                  <Grid item>
                    <Typography variant="h1">404</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      {t("notFound.errorPage")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ErrorOutlineOutlinedIcon
                      sx={{ fontSize: 72, margin: "9px 0" }}
                      color="error"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle">
                      {t("notFound.errorMessage1")}
                      <br />
                      {t("notFound.errorMessage2")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <img
                height="100%"
                width="auto"
                alt={t("notFound.dinoAlt")}
                src={dino}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default NotFoundPage;
