/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { Threat } from "src/types/InsightsResponse";

interface State {
  threats: Threat[];
  threatOverviewMetrics: any; // TODO: Add type?
}

const initialState: State = {
  threats: [],
  threatOverviewMetrics: {},
};

const ThreatsSlice = createSlice({
  name: "threats",
  initialState,
  reducers: {
    setThreats: (state, action) => {
      state.threats = action.payload;
    },
    setThreatOverviewMetrics: (state, action) => {
      state.threatOverviewMetrics = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setThreats, setThreatOverviewMetrics } = ThreatsSlice.actions;
export default ThreatsSlice.reducer;
