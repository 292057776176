import { useLocation, Navigate } from "react-router-dom";
import { getClientUuid } from "../helpers/security";
import NotFoundPage from "src/pages/notfound/NotFoundPage";
import { validate as uuidValidate } from "uuid";

const UuidRedirect = () => {
  const location = useLocation();
  const clientUUID = getClientUuid();

  const segments = location.pathname.split("/").filter(Boolean);
  if (!segments[0] || !uuidValidate(segments[0])) {
    const newPath = `/${clientUUID}${location.pathname}`;
    return <Navigate to={newPath + location.search} replace />;
  }
  return <NotFoundPage />;
};

export default UuidRedirect;
