import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useClientNavigate from "src/hooks/useClientNavigate";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useTheme } from "@mui/material/styles";

export const UpgradeButton = () => {
  const { t } = useTranslation();
  const navigate = useClientNavigate();
  const theme = useTheme();

  return (
    <IconButton
      id="upgrade-button"
      data-testid="upgrade-button"
      size="small"
      sx={{
        backgroundColor: theme.palette.highlight.main,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: theme.palette.highlight.main,
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        navigate("/contact-sales", {
          prepopulatedMessage: t("contact.sales.prepopulatedMessage"),
        });
      }}
    >
      <Typography
        color={theme.palette.verosintNavy.main}
        sx={{
          fontSize: "12px",
          whiteSpace: "nowrap",
          fontWeight: "bold",
        }}
      >
        {t("navBar.upgradeBtn")}
      </Typography>
      <ArrowOutwardIcon
        color={theme.palette.verosintNavy.main}
        sx={{
          width: "16px",
          height: "16px",
          verticalAlign: "middle",
          marginLeft: "4px",
        }}
      />
    </IconButton>
  );
};
