import React from "react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  isDisabled: boolean;
  children: any;
};

// TODO: re-use this component everywhere instead of having duplicate code
const UnavailableTooltip = (props: Props) => {
  const { isDisabled, children } = props;
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t("global.notAvailable")}
      placement="top"
      disableHoverListener={!isDisabled}
      disableFocusListener={!isDisabled}
      disableTouchListener={!isDisabled}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default UnavailableTooltip;
