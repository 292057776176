import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import { Box, Chip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateCurrentOption, updateNavPath } from "../../../redux/User/slice";
import useClientNavigate from "src/hooks/useClientNavigate";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { isClientFree } from "src/helpers/security";
import { useTheme } from "@mui/material/styles";
import DarkThreatIcon from "src/assets/threat.svg";
import BlueThreatIcon from "src/assets/threat-blue.svg";
import WhiteThreatIcon from "src/assets/threat-white.svg";
import ThreatsSelector from "src/redux/Threats/selector";
import { UpgradeButton } from "./UpgradeButton";
import { shouldUseDarkMode } from "src/styles/mainTheme";

type Props = {
  drawerWidth: number;
  openDrawer: boolean;
  handleDrawerOpen: Function;
  isSM: boolean;
};

const NavDrawer = (props: Props) => {
  const { drawerWidth, openDrawer, handleDrawerOpen, isSM } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useClientNavigate();
  const { pathname } = useLocation();
  const isFree = isClientFree();

  const { threatOverviewMetrics = {} } = useSelector(ThreatsSelector) || {};
  const activeThreatsCount = threatOverviewMetrics?.activeCount?.current || 0;
  const activeThreatsCountDisplay =
    activeThreatsCount >= 1000 ? "1K+" : activeThreatsCount;

  const setCurrentOption = (currentOption: string, title?: string) => {
    if (isSM) {
      handleDrawerOpen();
    }
    dispatch(updateNavPath(title));
    dispatch(updateCurrentOption(currentOption));
    navigate(`${currentOption}`);
  };

  const listItemStyles = (selected: boolean) => ({
    color: selected ? "#0170d1" : theme.palette.navText.main,
    fontWeight: selected ? "bold" : "normal",
  });

  const iconStyles = (selected: boolean) => ({
    color: selected ? "#0170d1" : theme.palette.navIcon.main,
    minWidth: "40px",
  });

  const isDarkMode = shouldUseDarkMode();
  const ThreatIcon = pathname.includes("/threats")
    ? BlueThreatIcon
    : isDarkMode
      ? WhiteThreatIcon
      : DarkThreatIcon;

  return (
    <Drawer
      className="nav-drawer"
      variant="persistent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        visibility: openDrawer ? "inherit" : "hidden",
        transform: openDrawer ? "inherit" : "translateX(-246px)",
      }}
      open={openDrawer}
    >
      <Toolbar />
      <List data-testid="navdrawer_list" sx={{ mt: 3 }}>
        <ListItemButton
          key="dashboard"
          data-testid="goto-dashboard-overview-link"
          selected={pathname.includes("/dashboard")}
          onClick={() => setCurrentOption("/dashboard")}
          sx={{
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
            mb: 1,
          }}
        >
          <ListItemIcon sx={iconStyles(pathname.includes("/dashboard"))}>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("navDrawer.dashboard")}
            sx={listItemStyles(pathname.includes("/dashboard"))}
          />
        </ListItemButton>
        <ListItem sx={{ pb: 2 }}>
          <Typography
            fontSize="15px"
            fontWeight="700"
            sx={{ textTransform: "uppercase" }}
          >
            {t("navDrawer.investigate")}
          </Typography>
        </ListItem>
        <ListItemButton
          key="threats"
          data-testid="goto-dashboard-threats-link"
          selected={pathname.includes("/threats")}
          onClick={() => setCurrentOption("/threats")}
          sx={{
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ListItemIcon sx={iconStyles(pathname.includes("/threats"))}>
            <Box sx={{ ml: 0.25, mb: -0.75 }}>
              <img src={ThreatIcon} style={{ width: "20px" }} />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={t("navDrawer.threats")}
            sx={listItemStyles(pathname.includes("/threats"))}
          />
          <Chip
            label={activeThreatsCountDisplay}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "white",
              borderRadius: "4px",
              height: "20px",
              fontWeight: "bold",
              fontSize: "12px",
              padding: "0 0px",
              mb: 0.15,
            }}
          />
        </ListItemButton>

        <ListItemButton
          key="accounts"
          data-testid="goto-accounts-link"
          selected={pathname.includes("/accounts")}
          onClick={() => setCurrentOption("/accounts")}
          sx={{
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ListItemIcon sx={iconStyles(pathname.includes("/accounts"))}>
            <PeopleOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("accountDetails.accounts")}
            sx={listItemStyles(pathname.includes("/accounts"))}
          />
        </ListItemButton>

        <ListItemButton
          key="event-explorer"
          data-testid="goto-event-explorer-link"
          selected={pathname.includes("/event-explorer")}
          onClick={() =>
            setCurrentOption("/event-explorer", t("eventExplorer.pageTitle"))
          }
          sx={{
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ListItemIcon sx={iconStyles(pathname.includes("/event-explorer"))}>
            <ExploreOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("eventExplorer.pageTitle")}
            sx={listItemStyles(pathname.includes("/event-explorer"))}
          />
        </ListItemButton>

        <ListItemButton
          key="signalPrint"
          data-testid="goto-signalPrint-link"
          selected={pathname.includes("/signalprint")}
          onClick={() => setCurrentOption("/signalprint")}
          sx={{
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ListItemIcon sx={iconStyles(pathname.includes("/signalprint"))}>
            <FingerprintOutlinedIcon />
          </ListItemIcon>
          <ListItemText sx={listItemStyles(pathname.includes("/signalprint"))}>
            {t("signalPrint.navTitle")} <sup>&trade;</sup>
          </ListItemText>
        </ListItemButton>

        <ListItem sx={{ pt: 3, pb: 2 }}>
          <Typography
            fontSize="15px"
            fontWeight="700"
            sx={{ textTransform: "uppercase" }}
          >
            {t("navDrawer.prevent")}
          </Typography>
        </ListItem>
        <ListItem key="lists" disablePadding>
          <ListItemButton
            data-testid="goto-lists-link"
            selected={pathname.includes("/lists")}
            onClick={() => setCurrentOption("/lists", t("lists.pageTitle"))}
            disabled={isFree}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ListItemIcon sx={iconStyles(pathname.includes("/lists"))}>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("lists.pageTitle")}
              sx={listItemStyles(pathname.includes("/lists"))}
            />
            {isFree && <UpgradeButton />}
          </ListItemButton>
        </ListItem>
        <ListItem key="workflows" disablePadding>
          <ListItemButton
            data-testid="goto-workflows-link"
            selected={pathname.includes("/workflows")}
            onClick={() =>
              setCurrentOption("/workflows", t("workflows.listTitle"))
            }
            disabled={isFree}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ListItemIcon sx={iconStyles(pathname.includes("/workflows"))}>
              <RuleOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={t("workflows.listTitle")}
              sx={listItemStyles(pathname.includes("/workflows"))}
            />
            {isFree && <UpgradeButton />}
          </ListItemButton>
        </ListItem>
      </List>
      <List sx={{ bottom: 0, marginTop: "auto" }}>
        <Divider className="drawer_divider" />
        <ListItem sx={{ pb: 2, pt: 2 }}>
          <Typography
            fontSize="15px"
            fontWeight="700"
            sx={{
              textTransform: "uppercase",
            }}
          >
            {t("navDrawer.tools")}
          </Typography>
        </ListItem>

        {/* Documentation */}
        <ListItemButton
          key="goto-documentation"
          data-testid="goto-documentation-link"
          component={Link}
          href="https://docs.verosint.com/"
          target="_blank"
          onClick={() => (isSM ? handleDrawerOpen() : null)}
          sx={{
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ListItemIcon sx={iconStyles(false)}>
            <DescriptionOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("documentation.listTitle")}
            sx={listItemStyles(false)}
          />
        </ListItemButton>
        <ListItemButton
          key="contact-support"
          data-testid="goto-contact-support-link"
          onClick={() => setCurrentOption("/contact-support")}
          sx={{
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ListItemIcon sx={iconStyles(pathname.includes("/contact-support"))}>
            <ContactSupportOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("contact.support.title")}
            sx={listItemStyles(pathname.includes("/contact-support"))}
          />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default NavDrawer;
