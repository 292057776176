// @ts-nocheck
import dayjs from "dayjs";
import { GraphSearchResponse, GraphSearchRequest } from "src/types/Graph";
import { EventRequest } from "src/types/EventRequest";

export function getGranularityOld(timeFrame) {
  return getGranularity(timeFrame.dateQuery[0], timeFrame.dateQuery[1]);
}

export function getGranularity(date1, date2) {
  if (!date1 || !date2) return "day";
  const diff = dayjs(date2).diff(dayjs(date1), "day", true);
  if (diff > 180) {
    return "month";
  }
  if (diff > 88) {
    return "week";
  }
  if (diff > 1) {
    return "day";
  }
  if (diff > 0.24) {
    return "hour";
  }
  return "minute";
}

export function getID(type: string, d: GraphSearchResponse) {
  if (type === "PRINT") {
    return d.printID;
  }
  if (type === "ACCT") {
    return d.accountID;
  }
  return d.identifierID;
}

export interface TimeFrame {
  dateQuery: string[];
}

export const createFetchData = (enqueueSnackbar: any, fetchEventData: any) => {
  return async (
    query: EventRequest,
    setData: (_: any) => void,
    errorMsg: string
  ) => {
    try {
      const result = await fetchEventData(query).unwrap();
      setData(result);
    } catch (e: any) {
      const error = e?.data?.error ?? e;
      enqueueSnackbar(errorMsg + ": " + error, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };
};

export function formatMTTR(seconds: number): string {
  const secondsInDay = 86400;
  const secondsInHour = 3600;
  const secondsInMinute = 60;

  if (seconds >= secondsInDay) {
    // 1 day or greater: display in days only (rounded to nearest day)
    const days = Math.round(seconds / secondsInDay);
    return `${days}d`;
  } else if (seconds >= secondsInHour) {
    // At least 1 hour: display hours and minutes (rounded to nearest minute)
    const hours = Math.floor(seconds / secondsInHour);
    let minutes = Math.round((seconds % secondsInHour) / secondsInMinute);
    // Edge: if minutes rounds to 60, increment the hour
    if (minutes === 60) {
      return `${hours + 1}h 0m`;
    }
    return `${hours}h ${minutes}m`;
  } else if (seconds >= secondsInMinute) {
    // Less than 1 hour: display minutes and seconds (rounded to nearest second)
    const minutes = Math.floor(seconds / secondsInMinute);
    let secs = Math.round(seconds % secondsInMinute);
    // Edge: if seconds rounds to 60, increment the minute
    if (secs === 60) {
      return `${minutes + 1}m 0s`;
    }
    return `${minutes}m ${secs}s`;
  } else {
    // Less than 1 minute: display seconds only (rounded to nearest second)
    return `${Math.round(seconds)}s`;
  }
}

export function formatCount(count: number): string {
  return new Intl.NumberFormat(navigator.language, {
    notation: "compact",
  }).format(count);
}

export function formatRoc(count: number): string {
  return count.toFixed(1) + "%";
}
