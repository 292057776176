import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Collapse,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  getClientUuid,
  isClientReadOnly,
  isClientAdmin,
  loadClient,
} from "src/helpers/security";
import { useGetProfileQuery } from "src/services/443id";
import sidebarLogoShield from "../../assets/logos/verosint-logo-dark.svg";
import sidebarLogoShieldMobile from "../../assets/logos/Verosint-shield-navbar.svg";
import useClientNavigate from "src/hooks/useClientNavigate";
import { Profile } from "src/types/Profile";
import MonthlyLimitsBanner from "./MonthlyLimitsBanner";
import { handleDemoNav } from "../ZeroEventsState/utils";
import useDemoClientCheck from "src/hooks/useDemoClientCheck";
import { BLUR_DEMO_KEY, IsLocalStorageBlurEnabled } from "src/helpers/store";
import storage from "local-storage-fallback";
import UnavailableTooltip from "../Misc/UnavailableTooltip";

const getFullName = (profile: Profile | undefined) => {
  if (!profile?.givenName && !profile?.familyName) {
    return undefined;
  } else if (!profile?.givenName) {
    return profile?.familyName;
  } else if (!profile?.familyName) {
    return profile?.givenName;
  } else {
    return `${profile?.givenName} ${profile?.familyName}`;
  }
};

type Props = {
  handleDrawerOpen: Function;
  padding: string;
  showUpgradeButton: boolean;
  showLimitExceededMessage: boolean;
};

const NavBar = ({
  handleDrawerOpen,
  padding,
  showUpgradeButton,
  showLimitExceededMessage,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  // @ts-expect-error
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const readOnly = isClientReadOnly();
  const isAdmin = isClientAdmin();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = useState(false);
  const navigate = useClientNavigate();
  const { user, logout, isAuthenticated } = useAuth0();
  const currentClientUUID = getClientUuid();
  // const greyColor = "#7B848B";
  const fontColor = theme.palette.charcoal.main;
  const { data: profile, isLoading: isGetProfileLoading } = useGetProfileQuery(
    undefined,
    { skip: !isAuthenticated }
  );
  const isDemo = useDemoClientCheck();
  const [isLocalBlurEnabled, setIsLocalBlurEnabled] = useState(
    IsLocalStorageBlurEnabled()
  );

  const currentClient = profile?.clients?.filter((client) => {
    return client.clientUUID === currentClientUUID;
  })?.[0];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const fullName = getFullName(profile);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const companyName = !currentClient?.company
    ? t("navBar.defaultTenant")
    : currentClient?.company;
  const companyDisplayName = isGetProfileLoading ? "" : companyName;

  // detect changes
  useEffect(() => {
    function checkBlur() {
      const item = storage.getItem(BLUR_DEMO_KEY);
      setIsLocalBlurEnabled(item === "true");
    }

    window.addEventListener("localStorageUpdate", checkBlur);

    return () => {
      window.removeEventListener("localStorageUpdate", checkBlur);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      className="nav-bar"
      sx={{
        height: showLimitExceededMessage ? "102px" : "64px",
        zIndex: (th) => th.zIndex.drawer + 1,
        backgroundColor: isDemo ? "transparent" : theme.palette.navBar.main,
        backgroundImage: isDemo
          ? `repeating-linear-gradient(
          -45deg, 
          ${theme.palette.hColor.main}, 
          ${theme.palette.hColor.main} 10px, 
          ${theme.palette.verosintNavy.main} 10px, 
          ${theme.palette.verosintNavy.main} 20px
        )`
          : "none",
      }}
      elevation={2}
    >
      <MonthlyLimitsBanner />
      <Toolbar sx={{ paddingRight: padding }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, color: "white" }}
          onClick={(e) => handleDrawerOpen(e)}
        >
          <MenuIcon />
        </IconButton>
        <img
          className="dashboard_logo"
          src={isSM ? sidebarLogoShieldMobile : sidebarLogoShield}
          alt={t("console.logoAlt")}
        />
        {isDemo && (
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => handleDemoNav(isDemo, profile)}
              data-testid="exit-demo-button"
              sx={{
                textTransform: "none",
                backgroundColor: theme.palette.accent.main,
                pl: 5,
                pr: 5,
                fontWeight: 600,
                color: "white",
              }}
              startIcon={<ArrowBackIcon />}
            >
              {t("global.exitDemoBtn").toUpperCase()}
            </Button>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <div>
          <Grid container alignItems="center">
            {showUpgradeButton && (
              <Grid item>
                <IconButton
                  id="upgrade-button"
                  data-testid="upgrade-button"
                  size="large"
                  sx={{
                    color: theme.palette.highlight.main,
                    fontSize: isSM ? "14px" : "18px",
                    fontWeight: 700,
                    mr: 2,
                    pt: 2,
                  }}
                  onClick={() =>
                    navigate("/contact-sales", {
                      prepopulatedMessage: t(
                        "contact.sales.prepopulatedMessage"
                      ),
                    })
                  }
                >
                  {t("navBar.upgradeBtn")}
                  <ArrowOutwardIcon
                    color={theme.palette.highlight.main}
                    sx={{
                      width: "24px",
                      height: "24px",
                      verticalAlign: "middle",
                    }}
                  />
                </IconButton>
              </Grid>
            )}
            {!isSM && (
              <Grid item>
                <Typography
                  data-testid="navbar_company-name"
                  fontWeight="700"
                  sx={{
                    display: "inline",
                    mr: 2,
                    filter: isLocalBlurEnabled ? "blur(4px)" : "none",
                    color: "white",
                  }}
                  fontFamily="Lato"
                >
                  {companyDisplayName}
                </Typography>
                <Typography
                  fontSize="20px"
                  fontFamily="Lato"
                  sx={{ display: "inline", mr: 1, color: "white" }}
                >
                  |
                </Typography>
              </Grid>
            )}
            <Grid item>
              <IconButton
                id="account-button"
                data-testid="account-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{ color: "white", mb: -0.5 }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <AccountCircleIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Menu
            id="account-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            disableScrollLock
          >
            <MenuItem
              sx={{ p: "8px 16px" }}
              data-testid="user"
              onClick={() => {
                navigate("/account/user-settings");
                handleClose();
              }}
            >
              <ListItemIcon sx={{ paddingRight: "8px" }}>
                <AccountCircleIcon
                  sx={{ fontSize: "32px", color: theme.palette.navIcon.main }}
                />
              </ListItemIcon>
              <ListItemText sx={{ marginLeft: "4px" }}>
                {fullName !== undefined && (
                  <Typography color={fontColor} fontWeight="700" fontSize="1">
                    {fullName}
                  </Typography>
                )}
                <Typography variant="p2Sub">{user?.email}</Typography>
              </ListItemText>
            </MenuItem>
            <Divider />
            {profile && profile?.clients?.length > 1 && (
              <Box>
                <MenuItem
                  sx={{ p: "8px 16px", position: "relative" }}
                  data-testid="tenant"
                  onClick={handleExpand}
                >
                  <ListItemIcon>
                    <DashboardIcon sx={{ color: theme.palette.navIcon.main }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ filter: isLocalBlurEnabled ? "blur(4px)" : "none" }}
                  >
                    <Typography
                      color={fontColor}
                      fontWeight="700"
                      data-testid="current-tenant"
                    >
                      {companyDisplayName}
                    </Typography>
                  </ListItemText>
                  <Box sx={{ right: "12px" }}>
                    {expanded ? (
                      <ExpandLessIcon sx={{ color: fontColor }} />
                    ) : (
                      <ExpandMoreIcon sx={{ color: fontColor }} />
                    )}
                  </Box>
                </MenuItem>
                <Collapse in={expanded} data-testid="tenants">
                  {profile?.clients
                    ?.slice()
                    .sort((a, b) => a?.company?.localeCompare(b?.company))
                    .map(({ clientUUID, company }) => {
                      const companyNameDropDown =
                        company || t("navBar.defaultTenant");
                      const selected = currentClientUUID === clientUUID;
                      let style: any = {
                        p: "8px 16px",
                        pl: 2,
                        filter: isLocalBlurEnabled ? "blur(4px)" : "none",
                      };
                      if (selected) {
                        style = {
                          ...style,
                          backgroundColor: `${theme.palette.primary.main}!important`,
                          opacity: "1!important",
                        };
                      }
                      return (
                        <MenuItem
                          key={clientUUID}
                          data-testid={companyNameDropDown}
                          selected={selected}
                          disabled={selected}
                          sx={style}
                          onClick={() => {
                            loadClient(clientUUID);
                          }}
                        >
                          <span style={{ marginLeft: "36px" }} />
                          <Typography
                            color={selected ? "white" : fontColor}
                            fontWeight="700"
                          >
                            {companyNameDropDown}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </Collapse>
                <Divider sx={{ pt: 1 }} />
              </Box>
            )}
            <ListItem sx={{ pt: 2, pb: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  textTransform: "uppercase",
                  color: theme.palette.subduedGray.main,
                }}
              >
                {t("global.accountSettings")}
              </Typography>
            </ListItem>
            <UnavailableTooltip isDisabled={readOnly}>
              <span>
                <MenuItem
                  sx={{ p: "8px 16px" }}
                  disabled={readOnly}
                  data-testid="api-keys-button"
                  onClick={() => {
                    navigate("/account/api-keys");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <KeyIcon sx={{ color: theme.palette.navIcon.main }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color={fontColor} fontWeight="700" fontSize="1">
                      {t("console.apiKeysBtn")}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </span>
            </UnavailableTooltip>
            <UnavailableTooltip isDisabled={!isAdmin}>
              <span>
                <MenuItem
                  sx={{ p: "8px 16px" }}
                  disabled={!isAdmin}
                  data-testid="api-usage-button"
                  onClick={() => {
                    navigate("/account/plans");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <CreditCardIcon
                      sx={{ color: theme.palette.navIcon.main }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color={fontColor} fontWeight="700" fontSize="1">
                      {t("console.apiUsageBtn")}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </span>
            </UnavailableTooltip>
            <UnavailableTooltip isDisabled={!isAdmin}>
              <span>
                <MenuItem
                  sx={{ p: "8px 16px" }}
                  disabled={!isAdmin}
                  data-testid="profile-edit-button"
                  onClick={() => {
                    navigate("/account/account-settings");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <SettingsIcon sx={{ color: theme.palette.navIcon.main }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color={fontColor} fontWeight="700" fontSize="1">
                      {t("console.settings")}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </span>
            </UnavailableTooltip>
            <Divider />
            <MenuItem
              sx={{ p: "8px 16px" }}
              data-testid="logout-button"
              onClick={handleLogOut}
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: theme.palette.navIcon.main }} />
              </ListItemIcon>
              <ListItemText>
                <Typography color={fontColor} fontWeight="700" fontSize="1">
                  {t("console.logoutBtn")}
                </Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
