const styles = {
  position: "absolute",
  width: "310px",
  height: "650px",
  top: "24px",
  right: "0px",
  zIndex: 1,
  opacity: "0.02",
  overflow: "hidden",
};

export default styles;
