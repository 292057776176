import "./themeExtension.d";
import {
  createTheme,
  Palette,
  TypographyVariants,
  ThemeOptions,
} from "@mui/material/styles";
import { datadogLogs } from "@datadog/browser-logs";

const { palette } = createTheme();

// source of truth: https://www.figma.com/design/k5PeJb38mNd7n9vkyTdFn6/Color-Themes?node-id=0-1&p=f&t=dKBidWQUO9PzPBCW-0
const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    // CTA Blue
    primary: {
      main: "#0070D1",
      contrastText: "#142027", // black in lightmode, white in darkmode
    },
    // Dark Navy
    secondary: {
      main: "#142748",
      contrastText: "rgb(245,245,245)", // white in lightmode, black in darkmode
    },
    navBar: {
      main: "#142748",
    },
    // Medium Blue
    hColor: {
      main: "#35527E",
    },
    success: {
      main: "#4CAF50",
    },
    highlight: palette.augmentColor({
      color: {
        main: "#01CCFF",
        dark: "#01CCFF",
      },
    }),
    // Light Gray
    background: {
      default: "rgb(249,250,251)",
      paper: "#FDFDFD",
    },
    darkBackground: {
      default: "#EEEEEE",
    },
    // Off White
    cardBackground: {
      default: "#FDFDFD",
    },
    // Medium Gray
    border: {
      default: "rgb(218,225,231)",
    },
    // CTA Orange
    accent: palette.augmentColor({
      color: {
        main: "#FE5000",
      },
    }),
    verosintNavy: palette.augmentColor({
      color: {
        main: "#1B365F",
      },
    }),
    charcoal: palette.augmentColor({
      color: {
        main: "#293135",
      },
    }),
    darkGray: palette.augmentColor({
      color: {
        main: "#646A70",
      },
    }),
    alertRed: palette.augmentColor({
      color: {
        main: "#E31B0C",
      },
    }),
    systemTag: palette.augmentColor({
      color: {
        main: "rgb(244,67,54)",
      },
    }),
    userTag: palette.augmentColor({
      color: {
        main: "rgb(102,102,102)",
      },
    }),
    payment: palette.augmentColor({
      color: {
        main: "#D5EFE5",
        dark: "rgb(89, 191, 150)",
      },
    }),
    phone: palette.augmentColor({
      color: {
        main: "#EDC3F0",
        dark: "#D100B0",
      },
    }),
    email: palette.augmentColor({
      color: {
        main: "#BFDBF3",
        dark: "rgb(0, 112, 209)",
      },
    }),
    ip: palette.augmentColor({
      color: {
        main: "#FCDDC7",
        dark: "rgb(244, 120, 32)",
      },
    }),
    print: palette.augmentColor({
      color: {
        main: "#FFECBF",
        dark: "rgb(255, 180, 0)",
      },
    }),
    event: palette.augmentColor({
      color: {
        main: "#DEE0E2",
        dark: "rgb(244,67,54)",
      },
    }),
    account: palette.augmentColor({
      color: {
        main: "#CCD4DF",
        dark: "rgb(53, 82, 126)",
      },
    }),
    tag: palette.augmentColor({
      color: {
        main: "rgb(233, 233, 233)",
      },
    }),
    userAgent: palette.augmentColor({
      color: {
        main: "#BFF2FF",
      },
    }),
    subduedGray: palette.augmentColor({
      color: {
        main: "#4E6174",
      },
    }),
    mustard: palette.augmentColor({
      color: {
        main: "#F1C600",
        dark: "#C38C00",
      },
    }),
    blueGray: palette.augmentColor({
      color: {
        main: "rgb(78,97,116)",
      },
    }),
    darkGreen: palette.augmentColor({
      color: {
        main: "#3B873E",
      },
    }),
    grape: palette.augmentColor({
      color: {
        main: "#911EB4",
      },
    }),
    headline: palette.augmentColor({
      color: {
        main: "#142748",
      },
    }),
    navIcon: palette.augmentColor({
      color: {
        main: "#4E6174",
      },
    }),
    navText: palette.augmentColor({
      color: {
        main: "#394856",
      },
    }),
    filterBar: palette.augmentColor({
      color: {
        main: "#bcbcbc",
      },
    }),
    accountCellHover: palette.augmentColor({
      color: {
        main: "rgb(228, 239, 249)",
      },
    }),
  } as Palette,
  typography: {
    subtext: {
      color: "#0E5A81",
    },
    fontFamily: "Karla, -apple-system",
    h1: {
      fontFamily: "Lato, -apple-system",
      color: "#35527E",
      fontSize: "36px",
      display: "block",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 700,
      color: "#35527E",
      fontSize: "28px",
      display: "block",
    },
    h2Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "28px",
      display: "block",
    },
    h3: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "20px",
      display: "block",
    },
    h3Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "20px",
      display: "block",
    },
    h4: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "18px",
      display: "block",
    },
    h4Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 400,
      color: "#142748",
      fontSize: "18px",
      display: "block",
    },
    h5: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "16px",
      textTransform: "uppercase",
      display: "block",
    },
    h5Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "16px",
      display: "block",
    },
    h6: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#35527E",
      fontSize: "14px",
      textTransform: "uppercase",
      display: "block",
    },
    h6Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#142748",
      fontSize: "14px",
      display: "block",
    },
    p: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "#142748",
      display: "block",
    },
    pBold: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "#142748",
      fontWeight: 700,
      display: "block",
    },
    pSub: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "#7C848B",
      display: "block",
    },
    p2: {
      fontFamily: "Karla, -apple-system",
      fontSize: "14px",
      color: "#142748",
      display: "block",
    },
    p2Sub: {
      fontFamily: "Karla, -apple-system",
      fontSize: "14px",
      color: "#7C848B",
      display: "block",
    },
    bigNumber: {
      fontFamily: "Lato, -apple-system",
      fontSize: "40px",
      fontWeight: "700",
      color: "rgb(35, 78, 162)",
      display: "block",
    },
    deltaDecrease: {
      fontFamily: "Lato, -apple-system",
      fontSize: "24px",
      fontWeight: "700",
      color: "rgb(244, 66, 54)",
      display: "block",
    },
    deltaIncrease: {
      fontFamily: "Lato, -apple-system",
      fontSize: "24px",
      fontWeight: "700",
      color: "rgb(37, 173, 21)",
      display: "block",
    },
    code: {
      fontFamily: "monospace",
      backgroundColor: "rgb(239, 240, 242)",
      color: "#35527E",
      padding: "1px 8px",
      fontSize: "14px",
    },
    label: {
      fontFamily: "Karla, -apple-system",
      fontSize: "12px",
      fontWeight: "400",
      textTransform: "uppercase",
      color: "#293135",
      display: "block",
      letterSpacing: "0.1em",
    },
  } as TypographyVariants,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.25)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          fontSize: "11px",
          height: "26px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#FDFDFD",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Dark buttons (contained primary, secondary, etc.) get white text
        containedPrimary: {
          color: "#ffffff",
          "& .MuiTypography-root": {
            color: "#ffffff",
          },
        },
        containedSecondary: {
          color: "#ffffff",
          "& .MuiTypography-root": {
            color: "#ffffff",
          },
        },
        // For light mode, transparent buttons (outlined, text) get dark text
        // This works well on light backgrounds in light mode
        outlined: {
          color: "#142027",
          "& .MuiTypography-root": {
            color: "#142027",
          },
        },
        text: {
          color: "#142027",
          "& .MuiTypography-root": {
            color: "#142027",
          },
        },
        // Color-specific text and outlined buttons should use their respective colors
        textPrimary: {
          color: "#0070D1",
          "& .MuiTypography-root": {
            color: "#0070D1",
          },
        },
        outlinedPrimary: {
          color: "#0070D1",
          "& .MuiTypography-root": {
            color: "#0070D1",
          },
        },
        textSecondary: {
          color: "#142748",
          "& .MuiTypography-root": {
            color: "#142748",
          },
        },
        outlinedSecondary: {
          color: "#142748",
          "& .MuiTypography-root": {
            color: "#142748",
          },
        },
        textError: {
          color: "#E31B0C",
          "& .MuiTypography-root": {
            color: "#E31B0C",
          },
        },
        outlinedError: {
          color: "#E31B0C",
          "& .MuiTypography-root": {
            color: "#E31B0C",
          },
        },
      },
      variants: [
        {
          props: { variant: "printid" },
          style: {
            textTransform: "none",
            display: "inline",
            margin: 0,
            padding: 0,
            fontFamily: "monospace",
            color: "#0070D1",
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#FDFDFD",
          border: "1px solid rgb(218,225,231)",
          borderRadius: "4px",
          boxShadow: "none",
          paddingBottom: "0",
        },
      },
    },
  },
};

const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    // CTA Blue
    primary: {
      main: "#2580D0",
      contrastText: "rgb(245,245,245)",
    },
    // Dark Navy
    secondary: {
      main: "#EBEEF1",
      contrastText: "rgb(0,0,0)",
    },
    navBar: {
      main: "#142748",
    },
    // Medium Blue
    hColor: {
      main: "#35527E",
    },
    success: {
      main: "#4CAF50",
    },
    highlight: palette.augmentColor({
      color: {
        main: "#01CCFF",
        dark: "#01CCFF",
      },
    }),
    // Light Gray
    background: {
      default: "#0E121B",
      paper: "#0E121B",
    },
    darkBackground: {
      default: "#394856",
    },
    // Off White
    cardBackground: {
      default: "#142027",
    },
    // Medium Gray
    border: {
      default: "#394856",
    },
    // CTA Orange
    accent: palette.augmentColor({
      color: {
        main: "#FF7B3D",
      },
    }),
    verosintNavy: palette.augmentColor({
      color: {
        main: "#1B365F",
      },
    }),
    charcoal: palette.augmentColor({
      color: {
        main: "rgb(245,245,245)",
      },
    }),
    darkGray: palette.augmentColor({
      color: {
        main: "#646A70",
      },
    }),
    alertRed: palette.augmentColor({
      color: {
        main: "#D7473C",
      },
    }),
    systemTag: palette.augmentColor({
      color: {
        main: "rgb(244,67,54)",
      },
    }),
    userTag: palette.augmentColor({
      color: {
        main: "rgb(102,102,102)",
      },
    }),
    payment: palette.augmentColor({
      color: {
        main: "#D5EFE5",
        dark: "rgb(89, 191, 150)",
      },
    }),
    phone: palette.augmentColor({
      color: {
        main: "#EDC3F0",
        dark: "#D100B0",
      },
    }),
    email: palette.augmentColor({
      color: {
        main: "#BFDBF3",
        dark: "rgb(0, 112, 209)",
      },
    }),
    ip: palette.augmentColor({
      color: {
        main: "#FCDDC7",
        dark: "rgb(244, 120, 32)",
      },
    }),
    print: palette.augmentColor({
      color: {
        main: "#FFECBF",
        dark: "rgb(255, 180, 0)",
      },
    }),
    event: palette.augmentColor({
      color: {
        main: "#DEE0E2",
        dark: "rgb(244,67,54)",
      },
    }),
    account: palette.augmentColor({
      color: {
        main: "#CCD4DF",
        dark: "rgb(53, 82, 126)",
      },
    }),
    tag: palette.augmentColor({
      color: {
        main: "rgb(233, 233, 233)",
      },
    }),
    userAgent: palette.augmentColor({
      color: {
        main: "#BFF2FF",
      },
    }),
    subduedGray: palette.augmentColor({
      color: {
        main: "#C1CAD2",
      },
    }),
    mustard: palette.augmentColor({
      color: {
        main: "#F1C600",
        dark: "#C38C00",
      },
    }),
    blueGray: palette.augmentColor({
      color: {
        main: "rgb(78,97,116)",
      },
    }),
    darkGreen: palette.augmentColor({
      color: {
        main: "#3B873E",
      },
    }),
    grape: palette.augmentColor({
      color: {
        main: "#911EB4",
      },
    }),
    headline: palette.augmentColor({
      color: {
        main: "#EBEEF1",
      },
    }),
    navIcon: palette.augmentColor({
      color: {
        main: "#C1CAD2",
      },
    }),
    navText: palette.augmentColor({
      color: {
        main: "#DAE1E7",
      },
    }),
    filterBar: palette.augmentColor({
      color: {
        main: "rgba(92,99,105,255)",
      },
    }),
    accountCellHover: palette.augmentColor({
      color: {
        main: "#35527E",
      },
    }),
  } as Palette,
  typography: {
    subtext: {
      color: "#0E5A81",
    },
    fontFamily: "Karla, -apple-system",
    h1: {
      fontFamily: "Lato, -apple-system",
      color: "rgb(245,245,245)",
      fontSize: "36px",
      display: "block",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 700,
      color: "rgb(245,245,245)",
      fontSize: "28px",
      display: "block",
    },
    h2Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "rgb(245,245,245)",
      fontSize: "28px",
      display: "block",
    },
    h3: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "rgb(245,245,245)",
      fontSize: "20px",
      display: "block",
    },
    h3Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "#EBEEF1",
      fontSize: "20px",
      display: "block",
    },
    h4: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "rgb(245,245,245)",
      fontSize: "18px",
      display: "block",
    },
    h4Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 400,
      color: "rgb(245,245,245)",
      fontSize: "18px",
      display: "block",
    },
    h5: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "rgb(245,245,245)",
      fontSize: "16px",
      textTransform: "uppercase",
      display: "block",
    },
    h5Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "rgb(245,245,245)",
      fontSize: "16px",
      display: "block",
    },
    h6: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "rgb(245,245,245)",
      fontSize: "14px",
      textTransform: "uppercase",
      display: "block",
    },
    h6Dark: {
      fontFamily: "Lato, -apple-system",
      fontWeight: 900,
      color: "rgb(245,245,245)",
      fontSize: "14px",
      display: "block",
    },
    p: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "rgb(245,245,245)",
      display: "block",
    },
    pBold: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "rgb(245,245,245)",
      fontWeight: 700,
      display: "block",
    },
    pSub: {
      fontFamily: "Karla, -apple-system",
      fontSize: "16px",
      color: "rgb(245,245,245)",
      display: "block",
    },
    p2: {
      fontFamily: "Karla, -apple-system",
      fontSize: "14px",
      color: "rgb(245,245,245)",
      display: "block",
    },
    p2Sub: {
      fontFamily: "Karla, -apple-system",
      fontSize: "14px",
      color: "rgb(245,245,245)",
      display: "block",
    },
    bigNumber: {
      fontFamily: "Lato, -apple-system",
      fontSize: "40px",
      fontWeight: "700",
      color: "rgb(245,245,245)",
      display: "block",
    },
    deltaDecrease: {
      fontFamily: "Lato, -apple-system",
      fontSize: "24px",
      fontWeight: "700",
      color: "rgb(244, 66, 54)",
      display: "block",
    },
    deltaIncrease: {
      fontFamily: "Lato, -apple-system",
      fontSize: "24px",
      fontWeight: "700",
      color: "rgb(37, 173, 21)",
      display: "block",
    },
    code: {
      fontFamily: "monospace",
      backgroundColor: "#142748",
      color: "rgb(245,245,245)",
      padding: "1px 8px",
      fontSize: "14px",
    },
    label: {
      fontFamily: "Karla, -apple-system",
      fontSize: "12px",
      fontWeight: "400",
      textTransform: "uppercase",
      color: "rgb(245,245,245)",
      display: "block",
      letterSpacing: "0.1em",
    },
  } as TypographyVariants,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.25)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          fontSize: "11px",
          height: "26px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#142027",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Dark buttons (contained primary, secondary, etc.) get white text
        containedPrimary: {
          color: "#ffffff",
          "& .MuiTypography-root": {
            color: "#ffffff",
          },
        },
        containedSecondary: {
          color: "#ffffff",
          "& .MuiTypography-root": {
            color: "#ffffff",
          },
        },
        // For dark mode, transparent buttons (outlined, text) get light text
        // This works well on dark backgrounds in dark mode
        outlined: {
          color: "rgb(245,245,245)",
          "& .MuiTypography-root": {
            color: "rgb(245,245,245)",
          },
        },
        text: {
          color: "rgb(245,245,245)",
          "& .MuiTypography-root": {
            color: "rgb(245,245,245)",
          },
        },
        // Color-specific text and outlined buttons should use their respective colors
        // These will override the default text/outlined styles above
        textPrimary: {
          color: "#2580D0",
          "& .MuiTypography-root": {
            color: "#2580D0",
          },
        },
        outlinedPrimary: {
          color: "#2580D0",
          "& .MuiTypography-root": {
            color: "#2580D0",
          },
        },
        textSecondary: {
          color: "#EBEEF1",
          "& .MuiTypography-root": {
            color: "#EBEEF1",
          },
        },
        outlinedSecondary: {
          color: "#EBEEF1",
          "& .MuiTypography-root": {
            color: "#EBEEF1",
          },
        },
        textError: {
          color: "#D7473C",
          "& .MuiTypography-root": {
            color: "#D7473C",
          },
        },
        outlinedError: {
          color: "#D7473C",
          "& .MuiTypography-root": {
            color: "#D7473C",
          },
        },
      },
      variants: [
        {
          props: { variant: "printid" },
          style: {
            textTransform: "none",
            display: "inline",
            margin: 0,
            padding: 0,
            fontFamily: "monospace",
            color: "#0070D1", // This variant uses a different color; leave it as needed
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#142027",
          border: "1px solid #394856",
          borderRadius: "4px",
          boxShadow: "none",
          paddingBottom: "0",
        },
      },
    },
  },
};

const getUserThemePreference = (): "light" | "dark" | "system" => {
  try {
    if (typeof window !== "undefined") {
      // set by the ProfileForm component
      const storedTheme = localStorage.getItem("theme");
      if (storedTheme && ["light", "dark", "system"].includes(storedTheme)) {
        return storedTheme as "light" | "dark" | "system";
      }
    }
  } catch (error) {
    datadogLogs.logger.error(
      "Error accessing localStorage: ",
      error as object | undefined
    );
  }

  // Default to system if no preference is found
  return "system";
};

export const shouldUseDarkMode = (): boolean => {
  const preference = getUserThemePreference();

  if (preference === "light") return false;
  if (preference === "dark") return true;

  return (
    window &&
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

const createAppTheme = () =>
  createTheme(shouldUseDarkMode() ? darkTheme : lightTheme);

let appTheme = createAppTheme();

if (typeof window !== "undefined") {
  // Listen for theme changes triggered by your app
  window.addEventListener("themeChange", () => {
    appTheme = createAppTheme();
    window.dispatchEvent(new Event("appThemeChanged"));
  });

  // Listen for system preference changes
  if (window.matchMedia) {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => {
        if (getUserThemePreference() === "system") {
          appTheme = createAppTheme();
          window.dispatchEvent(new Event("appThemeChanged"));
        }
      });
  }

  // Listen for localStorage changes (cross-tab synchronization)
  window.addEventListener("storage", (event) => {
    if (event.key === "theme") {
      appTheme = createAppTheme();
      window.dispatchEvent(new Event("appThemeChanged"));
    }
  });

  // Listen for the pageshow event (handles cached pages)
  window.addEventListener("pageshow", () => {
    appTheme = createAppTheme();
    window.dispatchEvent(new Event("appThemeChanged"));
  });

  // Ensure theme is up-to-date when the module is loaded in a new tab
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    appTheme = createAppTheme();
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      appTheme = createAppTheme();
    });
  }
}

export default appTheme;
